// https://github.com/kenwheeler/slick

import $ from 'jquery';
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'


/****** SLIDERS *******/
const sliders = {
    customersOpinion: $('.customers-opinion-slider')
}

sliders.customersOpinion.slick({
    prevArrow: $('.customers-opinion-slider-container .prev'),
    nextArrow: $('.customers-opinion-slider-container .next'),
});