//js
require('./js/sliders')
require('./js/vendor/modernizr-custom');
require('./js/vendor/jquery.scrollto');
require('./js/helper');
require('./js/navigation');
require('./js/maps');
require('./js/main');

//fonts
require('./fonts/nal-hand/stylesheet.css');

//admin panel
require('./css/admin.css');
require('./css/login.css');

require('./sass/style.scss');