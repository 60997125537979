// https://www.npmjs.com/package/animate-on-scroll
import animateOnScroll from 'animate-on-scroll';

// https://github.com/michalsnik/aos
import AOS from 'aos';
import 'aos/dist/aos.css';

(function($) {
  $(document).ready(function() {
    $(
      '.site-main > article > div, .site-main > article > section, .entry-content > div, .entry-content > section'
    ).attr('data-aos', 'fade-up');
  });

  $(window).load(function() {
    // $('.fade-on-scroll').animateOnScroll({
    // 	scale3d: {
    // 		enable: false
    // 	}
    // });

    AOS.init({
      offset: 200
    });

    function video() {
      var playButton = document.querySelector('a[href="#play-video"]'),
        video = document.getElementById('hero-video'),
        button = $('.banner .button');

      let videoDuration = (video.duration + 0.1) * 1000;

      playButton.addEventListener('click', function() {
        video.play();
        button.fadeOut('slow');

        setTimeout(() => {
          button.fadeIn();
          video.pause();
        }, videoDuration);
      });
    }
    video();
  });
})(jQuery);
