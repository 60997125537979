/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import Helper from './helper.js';
import $ from 'jquery';

const toggleMenu = () => {
	let button = document.getElementById('toggle'),
		container = document.getElementById('site-navigation'),
		html = document.querySelector('html');

		if (button && container) {
			button.addEventListener('click', () => {

				if(button.classList.contains('open')) {
					container.classList.remove('open');
					button.classList.remove('open');
					html.style.overflowY = 'auto';		
					return;
				}

				container.classList.add('open');
				button.classList.add('open');

				if (Helper.getScreenHeight() <= 300) {
					html.style.overflowY = 'hidden';
				} else {
					html.style.overflowY = 'auto';					
				}
	
			}) 
		}
} 
toggleMenu();

const closeMenu = () => {
	let links = document.querySelectorAll('.main-navigation a'),
		nav = document.getElementById('site-navigation'),
		toggle = document.getElementById('toggle');

	links.forEach( (e) => {
		e.addEventListener('click', () => {
			nav.classList.remove('open');
			toggle.classList.remove('open');
		})
	})
}

const addSticky = () => {
	let toggle = document.getElementById('toggle'),
		header = document.getElementById('masthead');

	toggle.addEventListener('click', () => {
		header.classList.add('sticky');
	})
	
}

// /****** STICKY HEADER *******/
// (function(){

// 	function add_header_class() {

// 		let windowTop = $(window).scrollTop(),
// 			header = document.getElementById('masthead');

// 		if (0 < windowTop) {
// 			header.addClass('sticky');
// 		} else {
// 			header.removeClass('sticky');
// 		}
// 	}

// 	$(window).on('scroll', add_header_class);

// })();

if (Helper.isMobile()) {
	closeMenu();
	addSticky();
}

window.onresize = () => {
	if (Helper.isMobile()) {
		closeMenu();
		addSticky();
	}
};

